.error-container {
  background-color: #fff;
  padding: 6%;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.error-page-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
}

.error-message {
  font-size: 24px;
  font-weight: bold;
  color: #e74c3c;
  margin: 20px 0;
}

.home-button {
  background-color: #3498db;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.home-button:hover {
  background-color: #2980b9;
}
