.video-div {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.video-div {
  /* width: 100%;
  height: 100%; */
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.pre-book-video-btn:hover {
  /* transition: all 0.1s; */
  transform: scale(1.05);
  transition: transform 0.3s ease-in-out;
  background: black;
  border: 2px solid white;
}
.pre-book-video-btn {
  z-index: 1100;
  color: white;
  font-size: 1rem;
  border-radius: 5px;
  position: fixed;
  bottom: 0.5rem;
  right: 2rem;
  background: rgba(0, 0, 0, 0.7);
  padding: 0.6vw 2rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.linkssssss a {
  margin-left: 1rem;
  color: white;
}
.linkssssss a:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease-in-out;
}
.linkssssss {
  font-size: 0.8rem;
  position: fixed;
  width: 100vw;
  bottom: 0;
  left: 0;
  /* border-radius: 15px; */
  padding: 1.3%;
  /* margin-bottom: 0.7rem; */
  z-index: 1000;
  display: flex;
  background-color: rgba(169, 169, 169, 0.5);
}

.homepage_video {
  /* height: 100vh;
    width: 100vw; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -1;
}

@media screen and (max-width: 1300px) {
  .pre-book-video-btn {
    bottom: 3rem;
    right: 2rem;
  }
}
