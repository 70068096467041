.spinner {
  width: var(--spinner_width);
  height: calc(var(--spinner_width) / 2.5);
  position: relative;
  border-radius: 50%;
  transform: scale(0.8);
}
.outer-spinner {
  width: 100%;
  height: calc(100vh - calc(var(--buy_modal_height) + var(--gap_between_selectors) + var(--variant_selector_height) + var(--gap_between_bar_selector) + var(--bar_selector_height) + var(--gap_between_bar_vehicle) + var(--height_header) + var(--margin_top_header)));
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.spinner div {
  position: absolute;
  top: 0;
  left: 0;
  width: 80%;
  margin-left: 7%;
  height: 100%;
  z-index: 6;
}
.spinner img {
  position: absolute;
}

.spinner img:nth-child(1) {
  top: -3%;
  left: -10%;
  width: 50%;
}
.spinner img:nth-child(2) {
  top: -16%;
  left: 9%;
  width: 43.3333%;
}
.spinner img:nth-child(3) {
  top: -15%;
  left: 30%;
  width: 26.6666%;
}
.spinner img:nth-child(4) {
  top: -15%;
  left: 40%;
  width: 26.6666%;
}
.spinner img:nth-child(5) {
  top: -16%;
  left: 43%;
  width: 43.3333%;
}
.spinner img:nth-child(6) {
  top: -6%;
  left: 53%;
  width: 50%;
}
.spinner img:nth-child(7) {
  top: 17%;
  left: 39%;
  width: 66.6666%;
}
.spinner img:nth-child(8) {
  top: -24%;
  left: -23%;
  width: 133.3333%;
}
.spinner img:nth-child(9) {
  top: 17%;
  left: -13%;
  width: 66.6666%;
}

.bar_container {
  width: 100vw;
  position: absolute;
  left: 0;
  display: flex;
  justify-content: center;
  bottom: calc(var(--buy_modal_height) + var(--gap_between_bar_selector) + var(--variant_selector_height) + var(--gap_between_bar_selector));
}

.bar div {
  border: 0.5px solid rgb(201, 201, 201);
}
.bar {
  z-index: 12;
  width: 50%;
  display: flex;
  align-items: center;
}
.bar_clickable {
  cursor: pointer;
}
.bar1,
.bar5 {
  width: 20%;
  height: 0.4vh;
}
.bar2,
.bar4 {
  width: 20%;
  height: 0.8vh;
}
.bar3 {
  width: 20%;
  height: 1.2vh;
  background: #050505;
}
.bar1 {
  background: #fcfcfc;
}
.bar2 {
  background: #739AA4;
}
.bar4 {
  background: #de8ec1;
}
.bar5 {
  background: #757180;
}

@media screen and (min-width: 100px) and (max-width: 1000px) and (min-height: 1000px) and (max-height: 1500px) {
  .spinner {
    transform: scale(1.9) translateX(2vw);
  }
  .bar {
    width: 70%;
  }
}
@media screen and (min-width: 500px) and (max-width: 1000px) and (min-height: 600px) and (max-height: 800px) {
  .spinner {
    transform: scale(1.9) translateX(2vw);
  }
  .bar {
    width: 70%;
  }
}

@media screen and (min-width: 200px) and (max-width: 500px) {
  .spinner {
    transform: scale(1.6) translateX(2vw) translateY(-1.3vh);
  }
  .bar {
    width: 70%;
  }
}
