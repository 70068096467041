.buy-modal {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
  display: flex;
  flex-direction: var(--buy_modal_flex_direction);
  box-sizing: border-box;
  padding: 0 9%;
  padding-bottom: calc(4vh + var(--lower_box_padding_bottom));
  color: white;
  background-color: rgba(253, 103, 14, 255);
  padding-top: 1vh;
  overflow: auto;
}

.input_outer_div {
  width: var(--input_outer_div_width);
  display: flex;
  flex-direction: column;
}

.city_selector {
  max-height: var(--city_selector_max_height) !important;
}
.state_selector {
  z-index: 1;
  font-size: var(--selector_content_font_size);
  position: absolute;
  top: 100%;
  left: 0;
  width: var(--input_width);
  margin-left: var(--input_margin_left);
  max-height: var(--state_selector_max_height);
  min-height: 104%;
  background-color: white;
  overflow-x: hidden;
  overflow-y: overlay;
  display: flex;
  flex-direction: column;
  color: black;
}

.select_input:hover,
.select_input:focus {
  background-color: rgb(234, 234, 234);
}
.select_input {
  cursor: pointer;
  padding-left: 5%;
  box-sizing: border-box;
}

select,
.input {
  width: var(--input_width);
  margin-left: var(--input_margin_left);
}

.input_outer_div_heading {
  width: var(--input_width);
  margin-top: 2vh;
  margin-bottom: 1vh;
  font-size: var(--selector_heading_font_size);
  margin-left: var(--input_margin_left);
}

.two_inputs {
  width: var(--input_width);
  display: flex;
  justify-content: space-between;
  margin-left: var(--input_margin_left);
}

.input,
select,
.two_inputs {
  margin-top: 1%;
}

.two_inputs input {
  width: 49%;
  display: flex;
}

select,
input {
  padding: var(--input_padding);
  box-sizing: border-box;
  border: 0;
  outline: none;
  font-size: var(--input_font_size);
}

@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: 1px;
  font-family: "Roboto", sans-serif;
}

.btn-focus {
  color: white !important;
}
.color_white {
  color: white;
}
.btn-focus:focus {
  border: 0.00001px solid white;
  background: transparent;
}
.place-order-next {
  letter-spacing: 1px;
  box-sizing: border-box;
  cursor: pointer;
  outline: none;
  border: 0.00001px solid transparent;
  font-family: "Futura Bk";
  background: transparent;
  font-size: var(--selector_content_font_size);
}
.place-order-next-disable {
  cursor: default;
  color: rgb(162, 162, 162);
}

.place-order-next-div {
  position: absolute;
  bottom: 3vh;
  right: 5vw;
  width: 90vw;
  display: flex;
  justify-content: space-between;
  color: white;
}

.booking_details_div {
  display: flex;
  flex-direction: column;
  width: var(--booking_details_div_width);
}

.booking_details_head {
  width: 100%;
  font-size: var(--selector_heading_font_size);
  margin-top: 2vh;
  margin-bottom: 1vh;
  display: flex;
}

.booking_content {
  width: var(--booking_content_width);
  display: flex;
  justify-content: space-between;
  font-size: var(--selector_content_font_size);
  margin-top: var(--booking_content_margin_top);
}
.booking_content_2 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: var(--selector_content_font_size);
  margin-top: var(--booking_content_margin_top);
}

.agree_terms_div {
  margin-top: 2%;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: calc(var(--selector_content_font_size) / 1.25);
}

.agree_box {
  width: 16px;
  height: 16px;
  border: 2px solid white;
  cursor: pointer;
  /* color: rgb(0, 215, 0); */
  box-sizing: border-box;
}

.transparent {
  color: transparent;
}

@media screen and (min-width: 100px) and (max-width: 888px) {
  .place-order-next-div {
    bottom: 2vh;
  }
}

@media screen and (min-width: 100px) and (max-width: 1000px) and (min-height: 1000px) and (max-height: 1200px) {
  :root {
    --input_font_size: 0.8rem;
  }
}
@media screen and (min-width: 100px) and (max-width: 1000px) and (min-height: 1200px) and (max-height: 1400px) {
  :root {
    --input_font_size: 0.8rem;
  }
}

@media screen and (min-width: 200px) and (max-width: 500px) {
  :root {
    --input_padding: 2vw 2vw;
    --input_font_size: 0.6rem;
    --booking_content_margin_top: 0.4vh;
  }
}
